<template>
  <section class="selection-list">
    <v-text-field
      v-model="search"
      label="Filter"
      prepend-inner-icon="mdi-magnify"
      class="mb-6"
      clearable
      flat
      solo
      hide-details
    />

    <v-list
      class="selections-list pt-0"
      dense
    >
      <v-list-item
        v-for="item in availableSelections"
        :key="`selections-list-item-${item.id}`"
        class="pl-0"
        dense
      >
        <v-checkbox
          :ripple="false"
          class="mt-0"
          dense
          hide-details
        >
          <template v-slot:label>
            <v-list-item-avatar
              v-if="item.image"
              class="ml-1 mr-2"
              size="20"
            >
              <v-img :src="item.image" />
            </v-list-item-avatar>
            <v-list-item-title class="body-2">{{ item.text }}</v-list-item-title>
            <v-list-item-icon
              class="ml-1"
              size="10"
              small
            >
              <v-icon
                v-if="item.verified"
                color="primary"
                small
              >mdi-check-decagram
              </v-icon>
            </v-list-item-icon>
          </template>
        </v-checkbox>
      </v-list-item>
    </v-list>
  </section>
</template>

<script>
export default {
  name: 'SelectionList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: '',
    search: null,
    isLoading: false,
    selected: [],
  }),
  computed: {
    availableSelections() {
      if ([null, ''].includes(this.search) || !this.search) return this.items;
      return this.items.filter((item) => item.text.includes(this.search));
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.selections-list {
  max-height: 300px;
  overflow-y: auto;
}

;
</style>
